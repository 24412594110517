export default [
  {
    id: 1,
    category_id: 8,
    title: "RoofScope",
  },
  {
    id: 4,
    category_id: 9,
    title: "GutterScope",
  },
  {
    id: 6,
    category_id: 14,
    title: "SidingScope",
  },
  {
    id: 7,
    category_id: 10,
    title: "InsulationScope",
  },
  {
    id: 10,
    category_id: 16,
    title: "ConcreteScope",
  },
  {
    id: 11,
    category_id: 31,
    title: "PaintScope",
  },
  {
    id: 12,
    category_id: 8,
    title: "RoofScopeX",
  },
  {
    id: 17,
    category_id: 0,
    title: "RoofScope+",
  }
]